import React, { useState } from "react";
import { Link, RichText } from "@sitecore-jss/sitecore-jss-react";
import CofNavigationMenu from "./CofNavigationMenu";

const CofHeader = ({ fields }) => {
  const [menuOpened, setMenuOpened] = useState(false);

  function handleMenuOpened() {
    setMenuOpened(!menuOpened);
  }

  return (
    <div className="generic-header p-4">
      <div className="container">
        <div className="logo-block">
          {fields?.TitleLink?.value ? (
            <Link field={fields.TitleLink}>
              <RichText field={fields.Title} />
            </Link>
          ) : (
            <RichText field={fields.Title} />
          )}
        </div>

        <div className="menu-block">
          <button onClick={handleMenuOpened} className="toggler-button">
            <span className="toggler-icon open"></span>
          </button>

          <CofNavigationMenu
            controller={handleMenuOpened}
            opened={menuOpened}
            data={fields.Navigation}
          />
        </div>
      </div>
    </div>
  );
};

export default CofHeader;
