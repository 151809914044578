import React, { useState } from "react";

const CofNavigationMenu = (props) => {
  // Expand / Collapse sub menu handler
  const [openedSub, setOpenedSub] = useState([]);

  const handleToggleSubMenu = (e) => {
    const itemId = e.currentTarget.id;

    if (openedSub.includes(itemId)) {
      setOpenedSub(openedSub.filter((item) => item !== itemId));
    } else {
      setOpenedSub([...openedSub, itemId]);
    }
  };

  // 2 levels menu builder
  const MenuBuilder = (data) => {
    const leveledMenu = [];

    for (let looper = 0; looper < data.length; looper++) {
      const pageUrl = data[looper].url;

      if (
        looper > 0 &&
        pageUrl.indexOf(leveledMenu[leveledMenu.length - 1].url) === 0
      ) {
        leveledMenu[leveledMenu.length - 1].subMenu.push(data[looper]);
      } else {
        data[looper].subMenu = [];
        leveledMenu.push(data[looper]);
      }
    }

    const menuItems = leveledMenu.map((item) => (
      <li
        key={item.id}
        id={item.id}
        className={openedSub.includes(item.id) ? "opened" : ""}
        onClick={handleToggleSubMenu}
      >
        {item.subMenu.length > 0 ? (
          <span>{item.fields.NavigationTitle.value}</span>
        ) : (
          <a href={item.url}>{item.fields.NavigationTitle.value}</a>
        )}

        {item.subMenu.length > 0 && MenuBuilder(item.subMenu)}
      </li>
    ));

    return <ul>{menuItems}</ul>;
  };

  return (
    <div className={`generic-navigation-menu ${props.opened ? "opened" : ""}`}>
      <div className="navi-toggler">
        <button
          onClick={() => {
            props.controller();
          }}
          className="toggler-button"
        >
          <span className="toggler-icon close"></span>
        </button>
      </div>
      <div className="navi-content">{MenuBuilder(props.data)}</div>
    </div>
  );
};

export default CofNavigationMenu;
